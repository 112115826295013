
import { gql } from '@apollo/client';

export const GET_AVAILABLE_PAYMENT_METHODS_QUERY = gql`
  query GetAvailablePaymentMethods($cartId: String!) {
    cart(cart_id: $cartId) {
      available_payment_methods {
        code
        title
      }
    }
  }
`;