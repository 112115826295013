
import { gql } from '@apollo/client';

export const GET_WISHLIST_QUERY = gql`
  query GetWishlist {
    wishlist {
      name
      items_count
      updated_at
      sharing_code
      items {
        id
        description
        added_at
        product {
          name
          sku
          delivery_estimation
          top_badge_left
          top_badge_right
          ... on ConfigurableProduct {
            configurable_options {
              id
              attribute_id_v2
              label
              position
              use_default
              attribute_code
              values {
                value_index
                label
              }
              product_id
            }
          }
          price_range {
            minimum_price {
              regular_price {
                value
                currency
              }
              final_price {
                value
                currency
              }
              discount {
                amount_off
                percent_off
              }
            }
            maximum_price {
              regular_price {
                value
                currency
              }
              final_price {
                value
                currency
              }
              discount {
                amount_off
                percent_off
              }
            }
          }
          image {
            url
            label
          }
          small_image {
            url
            label
          }
          media_gallery {
            url
            label
            ... on ProductVideo {
              video_content {
                media_type
                video_provider
                video_url
                video_title
                video_description
                video_metadata
              }
            }
          }
          short_description {
            html
          }
        }
      }
    }
  }
`;

export const GET_CUSTOMER_WISHLISTS = gql`
  query GetCustomerWishlists {
    customer {
      wishlists {
        id
        items_count
        items_v2 {
          items {
            id
            description
            added_at
            product {
              name
              sku
              delivery_estimation
              top_badge_left
              top_badge_right
              url_key
              ... on ConfigurableProduct {
                configurable_options {
                  id
                  attribute_id_v2
                  label
                  position
                  use_default
                  attribute_code
                  values {
                    value_index
                    label
                  }
                  product_id
                }
              }
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  final_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                }
                maximum_price {
                  regular_price {
                    value
                    currency
                  }
                  final_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                }
              }
              image {
                url
                label
              }
              small_image {
                url
                label
              }
              media_gallery {
                url
                label
                ... on ProductVideo {
                  video_content {
                    media_type
                    video_provider
                    video_url
                    video_title
                    video_description
                    video_metadata
                  }
                }
              }
              short_description {
                html
              }
            }
          }
        }
      }
    }
  }
`;