import { createTheme } from '@mui/material/styles';

// Define your custom color variables here
const customColors = {
  primary: '#10CDF2', // Replace with your primary color
  secondary: '#3F3E3E', // Replace with your secondary color
  // Add more custom colors as needed
};

// Create a custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: customColors.primary,
    },
    secondary: {
      main: customColors.secondary,
    },
  },
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif',
    ].join(','),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
      xl: 1920,
    },
  },
});

export default theme;