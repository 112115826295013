import './App.css';
import { ThemeProvider,CssBaseline } from '@mui/material';
import theme from './theme';
import MainComponent from './container/buyer/MainComponent';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
const clientId = '292304908257-f89c51r1toqj6qs8i59ek3u9rfc1shch.apps.googleusercontent.com';

// const theme = createTheme({
// ,});
function App() {
  return (
    <ThemeProvider theme={theme}>
      <GoogleOAuthProvider clientId={clientId}>
       <CssBaseline />
      <MainComponent/>
      
      </GoogleOAuthProvider>
   </ThemeProvider>
  );
}

export default App;
